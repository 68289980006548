import React, { useEffect, useState } from 'react';
import axios from 'axios';
import MaterialReactTable from 'material-react-table';
import { Link } from 'react-router-dom';

import AddNewItem from './AddNewItem';

import './OutFitterFlyout.css';

//Material UI Imports
import { Box, Button, ListItemIcon, MenuItem, Typography } from '@mui/material';

//Icons Imports
import { AccountCircle, Send } from '@mui/icons-material';

function MeganStuff() {
    const [clothingItems, setClothingItems] = useState([]);

    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(''); // Selected category state

    const [colours, setColours] = useState([]);
    const [selectedColour, setSelectedColour] = useState(''); // Selected colour state

    const [basket, setBasket] = useState([]);

    const addToBasket = (item) => {
        setBasket([...basket, item]);
    };

    const removeFromBasket = (itemToRemove) => {
        const updatedBasket = basket.filter((item) => item.filename !== itemToRemove.filename);
        console.log(itemToRemove.filename)
        setBasket(updatedBasket);
    };

    const clearBasket = () => {
        setBasket([]);
        toggleBasket()
    };

    const [showBasket, setShowBasket] = useState(false); // State to control basket visibility

    // Function to toggle the basket visibility
    const toggleBasket = () => {
        setShowBasket(!showBasket);
    };


    useEffect(() => {
        const port = 3003; // Replace with your desired port number
        const baseURL = `http://192.168.50.7:${port}`; // Construct the base URL with the port

        // const port = 5000; // Replace with your desired port number
        // const baseURL = `http://localhost:${port}`; // Construct the base URL with the port

        // Create an instance of Axios with the base URL
        const axiosInstance = axios.create({
            baseURL,
        });

        // Fetch categories from the backend
        axiosInstance.get('/api/megan/categories')
            .then((response) => {
                setCategories(response.data);
                console.log(response.data)
            })
            .catch((error) => {
                console.error('Error fetching categories:', error);
            });
        // Fetch colours from the backend
        axiosInstance.get('/api/megan/colours')
            .then((response) => {
                setColours(response.data);
                console.log(response.data)
            })
            .catch((error) => {
                console.error('Error fetching categories:', error);
            });

        // Make an API request to fetch clothing items
        axiosInstance.get('/api/megan')
            .then((response) => {
                setClothingItems(response.data);
                console.log(response.data)
            })
            .catch((error) => {
                console.error('Error fetching clothing items:', error);
            });
    }, []);


    // Define columns for the table
    const columns = [
        { header: 'Name', accessorKey: 'name' },
        { header: 'Description', accessorKey: 'description', canSort: true },
        {
            header: 'Image',
            canSort: true,
            accessorKey: 'filename', // Assuming 'imageURL' is the field containing image URLs
            Cell: ({ row }) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                    }}
                >
                    <div>
                        <img
                            src={row.original.filename}
                            height={50}
                            alt={row.original.name}
                            style={{ borderRadius: '50%' }}
                        />
                    </div>
                    {/* <span>{renderedCellValue}</span> */}
                </Box>
            ),
        },
        {
            header: 'Examples',
            canSort: true,
            accessorKey: 'examples', // Assuming 'filenames' is the field containing comma-separated filenames
            Cell: ({ row }) => (
                <div>
                {row.original.examples
                  ? row.original.examples.split(',').map((example, index) => (
                      <img
                        key={index}
                        src={example}
                        height={50}
                        alt={row.original.examples}
                        style={{ borderRadius: '50%', marginRight: '10px' }}
                      />
                    ))
                  : 'No images available'}
              </div>
            ),
          },
        {
            header: 'Actions',
            accessorKey: 'actions',
            Cell: ({ row }) => (
                <Button
                    variant="outlined"
                    onClick={() => addToBasket(row.original)}
                >
                    Add to Basket
                </Button>
            ),
        },
        // { Header: 'Price', accessorKey: 'price' },
        // { Header: 'Size', accessorKey: 'size' },
        // { Header: 'Color', accessorKey: 'color' },
        // { header: 'Owner', accessorKey: 'owner', canSort: true },
        // { header: 'Category', accessorKey: 'type', canSort: true },
    ];

    // Create separate filtered arrays for category and color
    const categoryFilteredItems = selectedCategory
        ? clothingItems.filter((item) => item.type === selectedCategory)
        : clothingItems;

    const colourFilteredItems = selectedColour
        ? clothingItems.filter((item) => item.color === selectedColour)
        : clothingItems;

    // Combine the filtered arrays (intersection)
    const filteredClothingItems = categoryFilteredItems.filter((item) =>
        colourFilteredItems.includes(item)
    );

    // // Filter clothing items based on the selected category
    //   const filteredClothingItems = selectedCategory
    //   ? clothingItems.filter((item) => item.type === selectedCategory)
    //   : clothingItems;

    return (
        <div>

            <Link to="/">
                <img
                    src="http://192.168.50.7/web_images/home.jpg" // Replace with the actual image path
                    alt="Go Home"
                    // width={200}
                    height={50}
                    style={{ borderRadius: '30%', cursor: 'pointer', margin: '5px' }}
                />
            </Link>
            <img
                src="http://192.168.50.7/images/megan.jpg" // Replace with the actual image path
                alt="Megan Catalog"
                // width={200}
                height={50}
                style={{ borderRadius: '30%', cursor: 'pointer', margin: '5px' }}
            />
            <Link to="/pete">
                <img
                    src="http://192.168.50.7/images/pete.jpg" // Replace with the actual image path
                    alt="Pete"
                    // width={200}
                    height={50}
                    style={{ borderRadius: '30%', cursor: 'pointer', margin: '5px' }}
                />
            </Link>

            {/* <h2>Outfit</h2> */}
            <div className="basket-container">
                <Button variant="outlined" onClick={toggleBasket} style={{ margin: '25px' }}>
                    View Outfit
                </Button>
                {showBasket && (
                    <div className="basket">
                        <ul>
                            {basket.map((item) => (
                                <li key={item.id}>
                                    <div>
                                        <img
                                            src={item.filename}
                                            alt={item.name}
                                            height={50}
                                            style={{ borderRadius: '50%' }}
                                        />
                                    </div>
                                    <div>
                                        <p>Name: {item.name}</p>
                                        <p>Description: {item.description}</p>
                                        {/* Add other item details here */}
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            onClick={() => removeFromBasket(item)}
                                        >
                                            Remove Item
                                        </Button>
                                    </div>
                                </li>
                            ))}
                        </ul>
                        <Button variant="outlined" color="secondary" onClick={clearBasket}>
                            Clear Basket
                        </Button>
                    </div>
                )}


                {/* Combo box for category selection */}
                <label htmlFor="categorySelect">Select a Category: </label>
                <select
                    id="categorySelect"
                    onChange={(e) => setSelectedCategory(e.target.value)}
                    value={selectedCategory}
                >
                    <option value="">All</option>
                    {categories.map((category) => (
                        <option key={category.type} value={category.type}>
                            {category.type}
                        </option>
                    ))}
                </select>

                {/* Combo box for colour selection */}
                <label htmlFor="colourSelect" style={{ margin: '25px' }}>Select a Colour: </label>
                <select
                    id="colourSelect"
                    onChange={(e) => setSelectedColour(e.target.value)}
                    value={selectedColour}
                >
                    <option value="" style={{ margin: '5px' }}>All</option>
                    {colours.map((colour) => (
                        <option key={colour.colour} value={colour.colour}>
                            {colour.colour}
                        </option>
                    ))}
                </select>
            </div>
            <MaterialReactTable
                columns={columns}
                data={filteredClothingItems}
                enableStickyHeader
                enableStickyFooter
                positionPagination='top'
                initialState={{ showColumnFilters: true, density: 'compact' }}
                // enableColumnFilterModes
                renderDetailPanel={({ row }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-evenly',
                            alignItems: 'center',
                        }}
                    >
                        <Box sx={{ textAlign: 'center' }}>
                            <Typography variant="h5">Details:</Typography>
                            <Typography variant="normal">
                                Description: {row.original.description}
                                <br></br>
                                Size: {row.original.size}
                                <br></br>
                                Colour: {row.original.color}
                                <br></br>
                                Price: £{row.original.price}
                            </Typography>
                        </Box>

                        <a href={row.original.filename} target="_blank" rel="noopener noreferrer">
                            <img
                                alt="avatar"
                                height={450}
                                src={row.original.filename}
                                loading="lazy"
                                style={{ borderRadius: '30%', cursor: 'pointer' }}
                            />
                        </a>

                    </Box>
                )}
            />


        <AddNewItem></AddNewItem>
        </div>

    );
}

export default MeganStuff;
