import React, { useEffect, useState } from 'react';
import axios from 'axios';
import MaterialReactTable from 'material-react-table';
import { Link } from 'react-router-dom';

//Material UI Imports
import { Box, Button, ListItemIcon, MenuItem, Typography } from '@mui/material';

//Icons Imports
import { AccountCircle, Send } from '@mui/icons-material';

function PeteStuff() {
  const [clothingItems, setClothingItems] = useState([]);

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(''); // Selected category state

  const [colours, setColours] = useState([]);
  const [selectedColour, setSelectedColour] = useState(''); // Selected colour state

  useEffect(() => {
    const port = 3003; // Replace with your desired port number
    const baseURL = `http://192.168.50.7:${port}`; // Construct the base URL with the port

    // Create an instance of Axios with the base URL
    const axiosInstance = axios.create({
      baseURL,
    });

    // Fetch categories from the backend
    axiosInstance.get('/api/pete/categories')
      .then((response) => {
        setCategories(response.data);
        console.log(response.data)
      })
      .catch((error) => {
        console.error('Error fetching categories:', error);
      });
    // Fetch colours from the backend
    axiosInstance.get('/api/pete/colours')
    .then((response) => {
      setColours(response.data);
      console.log(response.data)
    })
    .catch((error) => {
      console.error('Error fetching categories:', error);
    });

    // Make an API request to fetch clothing items
    axiosInstance.get('/api/pete')
      .then((response) => {
        setClothingItems(response.data);
        console.log(response.data)
      })
      .catch((error) => {
        console.error('Error fetching clothing items:', error);
      });
  }, []);

  const handleFileUpload = (event) => {

    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('image', file);

    const port = 3003; // Replace with your desired port number
    const baseURL = `http://192.168.50.7:${port}`; // Construct the base URL with the port

    // Create an instance of Axios with the base URL
    const axiosInstance = axios.create({
      baseURL,
    });

    // Use Axios or another HTTP library to send the file to the server
    axiosInstance.post('/api/upload', formData)
      .then((response) => {
        // Handle the response from the server (e.g., store the image URL)
        const imageURL = response.data.imageURL;
        console.log(imageURL)
        // Update your clothing item data with the imageURL
        // ...
      })
      .catch((error) => {
        console.error('Error uploading image:', error);
      });
  };

  // Define columns for the table
  const columns = [
    { header: 'Name', accessorKey: 'name' },
    {
      header: 'Image',
      canSort: true,
      accessorKey: 'filename', // Assuming 'imageURL' is the field containing image URLs
      Cell: ({ row }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          <div>
            <img
              src={row.original.filename}
              height={50}
              alt={row.original.name}
              style={{ borderRadius: '50%' }}
            />
          </div>
          {/* <span>{renderedCellValue}</span> */}
        </Box>
      ),
    },
    { header: 'Description', accessorKey: 'description', canSort: true },
    // { Header: 'Price', accessorKey: 'price' },
    // { Header: 'Size', accessorKey: 'size' },
    // { Header: 'Color', accessorKey: 'color' },
    // { header: 'Owner', accessorKey: 'owner', canSort: true },
    // { header: 'Category', accessorKey: 'type', canSort: true },
  ];

// Create separate filtered arrays for category and color
const categoryFilteredItems = selectedCategory
  ? clothingItems.filter((item) => item.type === selectedCategory)
  : clothingItems;

const colourFilteredItems = selectedColour
  ? clothingItems.filter((item) => item.color === selectedColour)
  : clothingItems;

// Combine the filtered arrays (intersection)
const filteredClothingItems = categoryFilteredItems.filter((item) =>
  colourFilteredItems.includes(item)
);

  return (
    <div>
      <h1>
      <Link to="/">
      <img
            src="http://192.168.50.7/web_images/home.jpg" // Replace with the actual image path
            alt="Go Home"
            // width={200}
            height={50}
            style={{ borderRadius: '30%', cursor: 'pointer', margin: '5px'  }}
          />
          </Link>

      <img
            src="http://192.168.50.7/images/pete.jpg" // Replace with the actual image path
            alt="Pete Catalog"
            // width={200}
            height={50}
            style={{ borderRadius: '30%', cursor: 'pointer', margin: '5px'  }}
          />
          <Link to="/megan">
          <img
            src="http://192.168.50.7/images/megan.jpg" // Replace with the actual image path
            alt="Megan"
            // width={200}
            height={50}
            style={{ borderRadius: '30%', cursor: 'pointer', margin: '5px'  }}
          />
        </Link>
                  </h1>


{/* Combo box for category selection */}
<label htmlFor="categorySelect">Select a Category: </label>
    <select
    id="categorySelect"
    onChange={(e) => setSelectedCategory(e.target.value)}
    value={selectedCategory}
    >
    <option value="">All</option>
    {categories.map((category) => (
        <option key={category.type} value={category.type}>
        {category.type}
        </option>
    ))}
    </select>   

{/* Combo box for colour selection */}
<label htmlFor="colourSelect" style={{margin:'25px'}}>Select a Colour: </label>
    <select
    id="colourSelect"
    onChange={(e) => setSelectedColour(e.target.value)}
    value={selectedColour}
    >
    <option value="" style={{margin:'5px'}}>All</option>
    {colours.map((colour) => (
        <option key={colour.colour} value={colour.colour}>
        {colour.colour}
        </option>
    ))}
    </select>   

      <MaterialReactTable
        columns={columns}
        data={clothingItems}
        renderDetailPanel={({ row }) => (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
            }}
          >
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="h5">Details:</Typography>
              <Typography variant="normal">
                Description: {row.original.description}
                <br></br>
                Size: {row.original.size}
                <br></br>
                Colour: {row.original.color}
                <br></br>
                Price: £{row.original.price}
              </Typography>
            </Box>
            
            <a href={row.original.filename} target="_blank" rel="noopener noreferrer">
              <img
                alt="avatar"
                height={450}
                src={row.original.filename}
                loading="lazy"
                style={{ borderRadius: '30%', cursor: 'pointer' }}
              />
            </a>

          </Box>
        )}
      />
      
    </div>
  );
}

export default PeteStuff;
