import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';

function HomePage() {
  return (
    <div>
      <Typography variant="h4">Hiyaaaa</Typography>

        <Link to="/pete">
          <img
            src="http://192.168.50.7/images/pete.jpg" // Replace with the actual image path
            alt="Pete"
            // width={200}
            height={200}
            style={{ borderRadius: '30%', cursor: 'pointer', margin: '5px'  }}
          />
        </Link>
        <Link to="/megan">
          <img
            src="http://192.168.50.7/images/megan.jpg" // Replace with the actual image path
            alt="Megan"
            // width={200}
            height={200}
            style={{ borderRadius: '30%', cursor: 'pointer', margin: '5px' }}
          />
        </Link>

      {/* Link to NewItem.js */}
      <Box mt={4}>
        <Link to="/add">
          <Button variant="contained" color="primary">
            Add New Item
          </Button>
        </Link>
      </Box>
    </div>
  );
}

export default HomePage;
