// src/App.js

import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ClothingList from './components/ClothingList';
import MeganStuff from './components/MeganStuff';
import PeteStuff from './components/PeteStuff';
import HomePage from './components/HomePage';
import NewItem from './components/NewItem';

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/pete" component={PeteStuff} />
          <Route exact path="/megan" component={MeganStuff} />
          <Route exact path="/add" component={NewItem} />
          {/* Add more routes for other components */}
        </Switch>
      </div>
    </Router>
  );
}

export default App;
