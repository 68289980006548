import React, { useState } from 'react';
import axios from 'axios';

function NewItem() {
      // State to hold the image preview URL
  const [imagePreview, setImagePreview] = useState(null);

    const port = 3003; // Replace with your desired port number

    const baseURL = `http://192.168.50.7:${port}`; // Construct the base URL with the port
    // const baseURL = `http://localhost:${port}`; // Construct the base URL with the port
    // const port = 3003; // Replace with your desired port number

    // Create an instance of Axios with the base URL
    const axiosInstance = axios.create({
        baseURL,
    });

  const [itemData, setItemData] = useState({
    name: '',
    description: '',
    size: '',
    color: '',
    price: '',
    image: null, // To store the selected image file
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setItemData({
      ...itemData,
      [name]: value,
    });
  };

  const handleImageUpload = (e) => {
    const imageFile = e.target.files[0];
    setItemData({
      ...itemData,
      image: imageFile,
    });

        // Create a preview URL for the selected image
        const previewURL = URL.createObjectURL(imageFile);
        setImagePreview(previewURL);

  };

  
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create a FormData object to send the image file
    const formData = new FormData();
    formData.append('image', itemData.image);
    console.log("new record...")
    console.log(itemData)

    try {
      // First, send the image to the server
      const imageUploadResponse = await axiosInstance.post('/api/upload', formData);
    console.log(imageUploadResponse)
    //   // Once the image is uploaded, use the returned imageURL to create the item record
    //   const newItemData = {
    //     name: itemData.name,
    //     description: itemData.description,
    //     size: itemData.size,
    //     color: itemData.color,
    //     price: itemData.price,
    //     image: imageUploadResponse.data.imageURL,
    //   };

    //   // Send a POST request to add the new item record to the database
    //   const itemPostResponse = await axiosInstance.post('/api/items', newItemData);

    //   // Handle success or display a message to the user
    //   console.log('Item added successfully:', itemPostResponse.data);
      // You can also redirect the user or perform other actions as needed
    } catch (error) {
      console.error('Error adding item:', error);
      // Handle errors and display an error message to the user
    }
  };

  return (
    <div>
      <h1>Add New Item</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={itemData.name}
            onChange={handleInputChange}
            required
          />
        </div>
        <div>
          <label htmlFor="name">Description:</label>
          <input
            type="text"
            id="description"
            name="description"
            value={itemData.description}
            onChange={handleInputChange}
            required
          />
        </div>
        <div>
          <label htmlFor="name">Size:</label>
          <input
            type="text"
            id="size"
            name="size"
            value={itemData.size}
            onChange={handleInputChange}
            required
          />
        </div>
        <div>
          <label htmlFor="name">Colour:</label>
          <input
            type="text"
            id="color"
            name="color"
            value={itemData.color}
            onChange={handleInputChange}
            required
          />
        </div>
        <div>
          <label htmlFor="name">Price:</label>
          <input
            type="text"
            id="price"
            name="price"
            value={itemData.price}
            onChange={handleInputChange}
            required
          />
        </div>
        {/* Add other input fields (description, size, color, price) */}
        <div>
          <label htmlFor="image">Image:</label>
          <input
            type="file"
            id="image"
            name="image"
            onChange={handleImageUpload}
            accept="image/*"
            required
          />
         
        </div>
        <div>
          <button type="submit">Add Item</button>
        </div>
      </form>
       {/* Display the image preview */}
       {imagePreview && (
            <img
              src={imagePreview}
              alt="Image Preview"
              height="500"
              style={{ marginTop: '10px', borderRadius: '10%' }}
            />
          )}
    </div>
  );
}

export default NewItem;
